import request from 'services/request';
import { endpoints } from 'services/endpoints';

export interface CicRequest {
  groupCode?: string;
  searchWord?: string;
}

export interface GroupCompaniesRequest {}

export interface GroupDomainsRequest {
  groupCode: string;
}

export interface CategoryRequest {
  cateType: string;
}

export interface UserInfoRequest {
  searchName: string;
}

export interface IntroAreasRequest {
  areaCode: string;
}

export interface LoggingRequest {
  preUri: string;
  reqUri: string;
  tfrom?: string;
}

export default {
  getCic: (params: CicRequest): Promise<any> =>
    request({
      url: endpoints.common.cic,
      method: 'get',
      params,
    }),
  getGroupCompanies: (params: GroupCompaniesRequest): Promise<any> =>
    request(
      {
        url: endpoints.common.groupCompanies,
        method: 'get',
        params: params,
      },
      {
        noAuth: true,
      }
    ),
  getGroupDomains: (params: GroupDomainsRequest): Promise<any> =>
    request(
      {
        url: endpoints.common.groupDomains,
        method: 'get',
        params: params,
      },
      {
        noAuth: true,
      }
    ),
  getCategory: (params: CategoryRequest): Promise<any> =>
    request({
      url: endpoints.common.category,
      method: 'get',
      params,
    }),
  getUserRank: (): Promise<any> =>
    request({
      url: endpoints.common.userRank,
      method: 'get',
    }),
  getIntroAreas: (params: IntroAreasRequest): Promise<any> =>
    request({
      url: endpoints.common.introAreas,
      method: 'get',
      params,
    }),
  getUserProfile: (): Promise<any> =>
    request({
      url: endpoints.common.profile,
      method: 'get',
    }),
  getPopularityKeyword: (): Promise<any> =>
    request({
      url: endpoints.common.popularityKeyword,
      method: 'get',
    }),
  getJobs: (): Promise<any> =>
    request(
      {
        url: endpoints.common.job,
        method: 'get',
      },
      {
        noAuth: true,
      }
    ),
  getPosition: (): Promise<any> =>
    request(
      {
        url: endpoints.common.position,
        method: 'get',
      },
      {
        noAuth: true,
      }
    ),
  getUserInfo: (url: string): Promise<any> =>
    request({
      url,
      method: 'get',
    }),
  getUserInfos: (params: UserInfoRequest): Promise<any> =>
    request({
      url: endpoints.common.userInfos,
      method: 'get',
      params,
    }),
  putReadCount: (params: CountAddReqDto): Promise<any> =>
    request({
      url: endpoints.common.read,
      method: 'put',
      data: params,
    }),
  putShareCount: (params: CountAddReqDto): Promise<any> =>
    request({
      url: endpoints.common.share,
      method: 'put',
      data: params,
    }),
  postLogging: (params: LoggingRequest): Promise<any> => {
    return request({
      url: endpoints.common.logging,
      method: 'post',
      data: params,
    });
  },
  /** NEW 뱃지 여부 확인 */
  displayNewBadgeStatus: (params: DisplayNewBadgeStatusListReqDto): Promise<DisplayNewBadgeStatusListResDto> => {
    return request({
      url: endpoints.common.displayNewBadgeStatus,
      method: 'post',
      data: params,
    });
  },
};
