import moment from 'moment';
import Paths from 'commons/paths';
import { decodeJwt, getYearsofService, mobileCheck } from 'utils/utils';
import { getHtmlFormatByEscapeHtmlData, millisToMinutesAndSeconds } from './LGLifeUtils';
import _ from 'lodash';
import { Contents, GaObject, Page, User, Search, Event } from '../types';
import { EventType } from '@testing-library/react';
import { BoardType } from '../types/enum/BoardType';

/*******************************
 * GA 관련 클릭 태깅 가이드 적용 Utils
 * - dataLayer : key 값 camelCase 필수 (GA 협의 사항)
 * - attribute : key 값 lowerCase 권장 (콘솔 에러)
 *******************************/

/* ****************************** *
 * (GA) GA 세팅 위한 Utils 변수 값
 * ****************************** */

/* ******************************
 * GA Utils 내부 전용 변수
 ******************************* */

/** 이벤트 유형 코드 목록 리스트 */
const EVENT_MENU_INFO_LIST = [
  { code: 'EVENT', name: '이벤트' },
  { code: 'EDITOR', name: '상시 이벤트' },
  { code: 'M_QUIZ', name: 'LG인 모의고사' },
  { code: 'QUIZ_WINNER', name: 'LG인 모의고사' },
  { code: 'SURVEY', name: 'LG인 설문조사' },
  { code: 'SIMPLE_SURVEY', name: '간단 설문' },
];

/** 커뮤니티 게시판 유형 코드 목록 리스트 */
const COMMUNITY_BOARD_MENU_INFO_LIST = [
  { code: 'BOARD', name: '커뮤니티' },
  { code: 'FREE', name: '자유게시판' },
  { code: 'TOP_EATS', name: '회사 옆 맛집' },
  { code: 'MENTIONS_BOARD', name: '칭찬합니다' },
  { code: 'DEPARTMENT_BOARD', name: '회사 속 직무' },
  { code: 'SOCIETY_BOARD', name: '회사 밖 소모임' },
];

/**
 * 생년월일 통해 만 나이 가져오기
 * @param birthYmd
 */
const getGAAgeGroupFromBirthYmd = (birthYmd: string) => {
  const dateFormat = 'YYYY.MM.DD';
  if (!moment(birthYmd, dateFormat, true).isValid()) return 'U';

  const birthDate = moment(birthYmd, dateFormat).toDate();

  // 만 나이 계산
  const age = moment().diff(birthDate, 'years');

  if (!age) return 'U';
  if (age < 25) return '18-24';
  if (age < 30) return '25-29';
  if (age < 35) return '30-34';
  if (age < 40) return '35-39';
  if (age < 45) return '40-44';
  if (age < 50) return '45-49';
  if (age < 55) return '50-54';
  if (age < 60) return '55-59';
  if (age < 65) return '60-64';

  return '65+';
};

/**
 * 생년월일 통해 생년 가져오기
 * @param birthYmd
 */
const getGABirthYear = (birthYmd: string) => {
  const dateFormat = 'YYYY.MM.DD';
  if (!moment(birthYmd, dateFormat, true).isValid()) return 0;

  const birthDate = moment(birthYmd, dateFormat).toDate();
  return birthDate.getFullYear();
};

/**
 * 성별 명(남/여/NONE) 가져오기
 * @param genderCode
 */
const getGAGenderName = (genderCode: 'M' | 'F' | 'NONE' | undefined) => {
  if (genderCode !== 'M' && genderCode !== 'F') return 'NONE';
  return genderCode;
};

/**
 * (GA) GA에 해당하는 contentType 반환
 * @param contentType
 * @param cateType
 * @param mediaContentsType
 * @param contentsDetailType
 * @param magazineType
 */
const getGAContentType = ({
  contentType,
  mediaContentsType,
  contentsDetailType,
  magazineType,
}: {
  contentType: string;
  mediaContentsType?: string;
  contentsDetailType?: string;
  magazineType?: string;
}) => {
  switch (contentType) {
    case 'CARD':
      if (magazineType) {
        if (magazineType === 'CARD_COMICS') return '웹툰(갓찌)';
        if (magazineType === 'WEEK_NEWSLETTER') return '주간 뉴스레터';
        return '매거진';
      }
      if (mediaContentsType) {
        if (contentsDetailType && contentsDetailType === 'CARD_COMICS') return '웹툰(갓찌)';
        return '매거진';
      }
      return '매거진';
    case 'NEWS':
    case 'PRESS':
      return getGaNewsType({
        cateType: contentType === 'PRESS' ? 'PRESS' : 'NEWS',
      });
    case 'BOARD':
      return '커뮤니티';
    case BoardType.FREE.toString():
      return '자유게시판';
    case BoardType.TOP_EATS.toString():
      return '회사 옆 맛집';
    case BoardType.MENTIONS_BOARD.toString():
      return '칭찬합니다';
    case BoardType.DEPARTMENT_BOARD.toString():
      return '회사 속 직무';
    case BoardType.SOCIETY_BOARD.toString():
      return '회사 밖 소모임';
  }
  return '영상';
};

/**
 * 이벤트 유형(eventType)을 전달 받아, GA 에 넘길 이벤트 메뉴명 가져오기
 * @param eventType 이벤트 유형
 */
const getGAEventMenuName = (eventType: string) => {
  if (EVENT_MENU_INFO_LIST.every(type => type.code !== eventType)) return '상시 이벤트';
  if (eventType === 'SURVEY' || eventType === 'SIMPLE_SURVEY') return 'LG인 설문조사';
  if (eventType === 'M_QUIZ' || eventType === 'QUIZ_WINNER') return 'LG인 모의고사';
  return '상시 이벤트';
};

/**
 * 커뮤니티 게시판 유형(boardType)을 전달 받아, GA 에 넘길 커뮤니티 게시판 메뉴명 가져오기
 * @param boardType 커뮤니티 게시판 유형
 */
const getGACommunityBoardMenuName = (boardType: string) => {
  if (COMMUNITY_BOARD_MENU_INFO_LIST.every(type => type.code !== boardType)) return '자유게시판';
  return (COMMUNITY_BOARD_MENU_INFO_LIST.find(type => type.code === boardType)?.name as string) || '자유게시판';
};

/* ******************************
 * GA Utils 외부 전용 변수
 ******************************* */

/**
 * (GA) LG NEWS 관련 GA 호출 위한 Type
 * @param cateType
 * @param mediaContentType
 */
export const getGaNewsType = ({ cateType }: { cateType: string }) => {
  if (cateType === 'NEWS') return `보도자료`;
  if (cateType === 'GROUP') return 'GROUP';
  return `언론 속 LG`;
};

/* ****************************** *
 * (GA) Interface 관련 매핑
 * ****************************** */

/**
 * (GA) GA Interface -> local history 갱신
 *
 * @param userId 사용자 ID
 */
export const fetchGAInterfaceToLocalStorage = ({ userId = '' } = {}) => {
  // ga_mp 스크립트에서 새로고침 시, userId 못 불러오는 이슈로 인해 로컬 스토리지 저장 - 2023.01.31
  localStorage.setItem('ga_user_id', userId || window.ga_object.User.unique_ID || ''); // 유저 ID
  localStorage.setItem('ga_object', JSON.stringify({ ...window.ga_object }));
};

/**
 * GA Object 초기 값 세팅 (Init)
 */
export const initGAObject = () => {
  if (!window.ga_object) {
    window.ga_object = {
      Contents: {} as Contents,
      User: {} as User,
      Search: {} as Search,
      Page: {} as Page,
      Event: {} as Event,
    } as GaObject;
  }

  // init
  window.ga_object.User.affiliate_info = ''; //계열사 정보
  window.ga_object.User.position_info = ''; //직급|직위 정보
  window.ga_object.User.task_info = ''; //직무 정보
  window.ga_object.User.continuous_service_year = 0; //근속년수
  window.ga_object.User.unique_ID = ''; //유니크 ID
  window.ga_object.User.member_grade = ''; //회원등급
  window.ga_object.User.member_gender = ''; //회원 성별
  window.ga_object.User.member_birth_year = 0; // 생년
  window.ga_object.User.member_age_group = ''; // 연령대

  window.ga_object.Page.page_name = Paths.getPageName(window.location.pathname) as string; //화면정의서에 명시된 페이지 이름
  window.ga_object.Page.page_URL = window.location.href; //해당 페이지 url
  window.ga_object.Page.device_channel = mobileCheck() ? 'Mobile' : 'PC'; //PC인지 Mobile인지
  window.ga_object.Page.login_status = 'N';

  fetchGAInterfaceToLocalStorage();
};

/**
 * 유저 정보 GA Object 매핑
 * @param info
 */
export const setGACommon = info => {
  initGAObject();
  window.ga_object.Page.page_name = Paths.getPageName(window.location.pathname) as string; //화면정의서에 명시된 페이지 이름
  window.ga_object.Page.page_URL = window.location.href; //해당 페이지 url
  window.ga_object.Page.device_channel = mobileCheck() ? 'Mobile' : 'PC'; //PC 인지 Mobile인지
  window.ga_object.Page.login_status = info ? 'Y' : 'N'; //로그인 여부에 따라 Y, N값으로 세팅

  window.ga_object.Contents.contents_tags = '';
  window.ga_object.Contents.contents_type = '';
  window.ga_object.Contents.contents_name = '';
  window.ga_object.Contents.contents_length = '';
  window.ga_object.Contents.contents_category = '';
  window.ga_object.Contents.contents_maker = '';
  window.ga_object.Contents.contents_brightCoveID = '';
  window.ga_object.Contents.contents_belong = '';

  window.ga_object.Event.event_type = '';
  window.ga_object.Event.event_name = '';
  window.ga_object.Event.event_URL = '';
  window.ga_object.Event.event_status = '';
  window.ga_object.Event.event_belong = '';

  window.ga_object.Search.searchKeyword = '';
  window.ga_object.Search.searchResultCount = 0;

  if (Paths.isPrivate(window.location.pathname) && info) {
    const jwt = decodeJwt(localStorage.getItem('llAccessToken'));

    window.ga_object.User.affiliate_info = info.groupNm; //계열사 정보
    window.ga_object.User.position_info = info.positionNm; //직급|직위 정보
    window.ga_object.User.task_info = info.job; //직무 정보
    let _timeStamp = info.hiredYmd;
    if (typeof info.hiredYmd === 'string') {
      _timeStamp = info.hiredYmd.replace(/\./gi, '-');
    }

    window.ga_object.User.continuous_service_year = getYearsofService(moment(_timeStamp)); //근속년수
    window.ga_object.User.unique_ID = jwt.userId; //유니크 ID
    window.ga_object.User.member_grade = jwt.rank; //회원등급
    window.ga_object.User.mypage_keyword = info.intrKeyword ? info.intrKeyword.slice(1).replace(/#/g, '_') : '';
    window.ga_object.User.member_birth_year = getGABirthYear(info.birthYmd); // 생년
    window.ga_object.User.member_age_group = getGAAgeGroupFromBirthYmd(info.birthYmd); // 나이
    window.ga_object.User.member_gender = getGAGenderName(info.genderCode); // 성별

    // ga_mp 스크립트에서 새로고침 시, userId 못 불러오는 이슈로 인해 로컬 스토리지 저장 - 2023.01.31
    fetchGAInterfaceToLocalStorage({
      userId: info.userId,
    });
  }
};

/**
 * (GA) ga_object 관련 Interface value 매핑
 * @param cateType
 * @param userInfo
 * @param mediaDetailData
 * @param eventDetailData
 * @param boardDetailData
 * @param topEatsBoardDetailData
 * @param mentionsBoardDetailData
 * @param departmentBoardDetailData
 * @param searchResultData
 * @param searchKeyword
 */
export const setGAInterfaceMapping = ({
  cateType,
  userInfo,
  mediaDetailData,
  eventDetailData,
  boardDetailData,
  topEatsBoardDetailData,
  mentionsBoardDetailData,
  societyBoardDetailData,
  searchKeyword,
  searchResultData,
}: {
  cateType: string;
  userInfo: UserDetailInfoResDto;
  mediaDetailData?: MediaDetailResDto;
  eventDetailData?: EventDetailResDto;
  boardDetailData?: BoardDetailResDto;
  topEatsBoardDetailData?: TopEatsBoardDetailResDto;
  mentionsBoardDetailData?: MentionsBoardDetailResDto;
  societyBoardDetailData?: DepartmentBoardDetailResDto;
  searchKeyword?: string;
  searchResultData?: SearchResultListResDto;
}) => {
  const allowedCateTypeList = ['VIDEO', 'NEWS', 'PRESS', 'CARD', 'SEARCH', 'BOARD', 'EVENT', 'TOP_EATS', 'GROUP'];

  if (!allowedCateTypeList?.some(item => item === cateType)) return;

  setGACommon(userInfo);

  switch (cateType) {
    case 'MENTIONS':
      window.ga_object.Contents.contents_type = '칭찬합니다';
      window.ga_object.Contents.contents_name = (mentionsBoardDetailData?.title as string) || '';
      window.ga_object.Contents.contents_category = (mentionsBoardDetailData?.boardCateNm as string) || '';
      window.ga_object.Contents.contents_belong = '커뮤니티';
      break;
    case 'TOP_EATS':
      window.ga_object.Contents.contents_type = '회사 옆 맛집';
      window.ga_object.Contents.contents_name = (topEatsBoardDetailData?.title as string) || '';
      window.ga_object.Contents.contents_category = (topEatsBoardDetailData?.boardCateNm as string) || '';
      window.ga_object.Contents.contents_belong = '커뮤니티';
      break;
    case 'BOARD':
      window.ga_object.Contents.contents_type = '자유게시판';
      window.ga_object.Contents.contents_name = (boardDetailData?.title as string) || '';
      window.ga_object.Contents.contents_category = (boardDetailData?.boardCateNm as string) || '';
      window.ga_object.Contents.contents_belong = '커뮤니티';
      break;
    case 'DEPARTMENT_BOARD':
      window.ga_object.Contents.contents_type = '회사 속 직무';
      window.ga_object.Contents.contents_name = (boardDetailData?.title as string) || '';
      window.ga_object.Contents.contents_category = (boardDetailData?.boardCateNm as string) || '';
      window.ga_object.Contents.contents_belong = '커뮤니티';
      break;
    case 'SOCIETY_BOARD':
      window.ga_object.Contents.contents_type = '회사 밖 소모임';
      window.ga_object.Contents.contents_name = (boardDetailData?.title as string) || '';
      window.ga_object.Contents.contents_category = (boardDetailData?.boardCateNm as string) || '';
      window.ga_object.Contents.contents_belong = '커뮤니티';
      break;
    case 'SEARCH':
      window.ga_object.Search.searchKeyword = (searchKeyword as string) || '';
      window.ga_object.Search.searchResultCount =
        (searchResultData?.boardCnt as number) +
        (searchResultData?.eventCnt as number) +
        (searchResultData?.faqCnt as number) +
        (searchResultData?.mediaCnt as number) +
        (searchResultData?.newsCnt as number) +
        (searchResultData?.cardNewsCnt as number) +
        (searchResultData?.cardComicsCnt as number) +
        (searchResultData?.pressCnt as number);

      break;
    case 'EVENT':
      window.ga_object.Event.event_type = getGAEventMenuName(eventDetailData?.eventType as string) || '상시 이벤트';
      window.ga_object.Event.event_name = eventDetailData?.title as string;
      window.ga_object.Event.event_URL = window.location.href;
      window.ga_object.Event.event_status = eventDetailData?.eventStatus === 'END' ? '종료' : '진행중';
      window.ga_object.Event.event_belong = '이벤트';
      break;
    case 'NEWS':
      window.ga_object.Contents.contents_type = '보도자료';
      window.ga_object.Contents.contents_name = (mediaDetailData?.title as string) || '';
      window.ga_object.Contents.contents_length = millisToMinutesAndSeconds(mediaDetailData?.editorContents?.length);
      window.ga_object.Contents.contents_category = (mediaDetailData?.cateNm as string) || '';
      window.ga_object.Contents.contents_maker = (mediaDetailData?.creatorNm as string) || '';
      window.ga_object.Contents.contents_belong = '콘텐츠';
      break;
    default:
      window.ga_object.Contents.contents_tags = mediaDetailData?.mediaTag?.slice(1).replace(/#/g, '_') as string;
      window.ga_object.Contents.contents_type = getGAContentType({
        contentType: mediaDetailData?.cateType as string,
        mediaContentsType: mediaDetailData?.mediaContentType as string,
        contentsDetailType: mediaDetailData?.contentsDetailType as string,
      });
      window.ga_object.Contents.contents_name = (mediaDetailData?.title as string) || '';
      window.ga_object.Contents.contents_length = millisToMinutesAndSeconds(mediaDetailData?.videoTotalTime);
      window.ga_object.Contents.contents_category = (mediaDetailData?.cateNm as string) || '';
      window.ga_object.Contents.contents_maker = (mediaDetailData?.creatorNm as string) || '';
      window.ga_object.Contents.contents_brightCoveID = (mediaDetailData?.videoId as string) || '';
      window.ga_object.Contents.contents_belong = '콘텐츠';
  }

  fetchGAInterfaceToLocalStorage();
};

/* ********************************************************************** *
 * (GA) GA 관련 이벤트 추후 all_click_datalayer_push 함수 호출 위한 attribute 셋팅
 * ********************************************************************** */

/**
 * (GA) 정렬 등 기타 클릭 GA
 * @param dataclickname
 * @param dataclickarea
 */
export const getGAClickProps = (dataclickname: string, dataclickarea: string) => {
  return {
    dataclick: 'click',
    dataclickname: dataclickname,
    dataclickarea: dataclickarea,
    datachannel: mobileCheck() ? 'Mobile' : 'PC',
  };
};

/**
 * (GA) 홈 메인 -> LNB 메뉴 클릭
 *
 * @param dataclickarea 클릭 영역 (1 depth)
 * @param dataClick
 * @param dataclickname (상세) 클릭 텍스트
 */
export const getGAMenuClickProps = (dataclickarea: string, { dataClick = '', dataclickname = '' } = {}) => {
  return {
    dataclick: dataClick || 'menuClick',
    dataclickname: dataclickname || dataclickarea,
    dataclickarea: dataclickarea,
    datachannel: mobileCheck() ? 'Mobile' : 'PC' || 'PC',
  };
};

/**
 * (GA) 메인 배너
 * @param name
 */
export const getGABannerClickProps = (name: string) => {
  return {
    dataclickname: name,
    dataclickarea: '띠배너',
    datachannel: mobileCheck() ? 'Mobile' : 'PC',
  };
};

/**
 * (GA) 알림 버튼 클릭
 * @param contents 알림 내용
 * @param isEpClick EP 클릭 여부
 */
export const getAlarmGAProps = ({
  contents,
  isEpClick,
}: {
  /** 알림 내용 */
  contents: string;
  /** EP 클릭 여부 */
  isEpClick: boolean;
}) => {
  let alarmContents = contents;

  if (alarmContents && alarmContents.indexOf('님이') > -1) {
    alarmContents = contents.split('님이')?.[1];
  }

  const qnaAlarmMessage = '1:1 문의하기를 남겼습니다.';
  const recordLikeAlarmMessage = '내 게시물에 좋아요를 눌렀습니다.';
  const commentLikeAlarmMessage = '내 댓글에 좋아요를 눌렀습니다.';
  const replyCommentLikeAlarmMessage = '내 답글에 좋아요를 눌렀습니다.';
  const commentAlarmMessage = '내 게시물에 댓글을 달았습니다.';
  const replyCommentAlarmMessage = '내 댓글에 답글을 달았습니다.';

  if (contents.includes(qnaAlarmMessage)) {
    alarmContents = qnaAlarmMessage;
  } else if (contents.includes(recordLikeAlarmMessage)) {
    alarmContents = recordLikeAlarmMessage;
  } else if (contents.includes(commentLikeAlarmMessage)) {
    alarmContents = commentLikeAlarmMessage;
  } else if (contents.includes(replyCommentLikeAlarmMessage)) {
    alarmContents = replyCommentLikeAlarmMessage;
  } else if (contents.includes(commentAlarmMessage)) {
    alarmContents = commentAlarmMessage;
  } else if (contents.includes(replyCommentAlarmMessage)) {
    alarmContents = replyCommentAlarmMessage;
  }

  return {
    dataclick: isEpClick ? 'epClick' : 'alarmClick',
    dataclickarea: 'alarm',
    dataclickname: String(alarmContents || ''),
    datachannel: mobileCheck() ? 'Mobile' : 'PC' || 'PC',
  };
};

/**
 * (GA) FAQ 영역
 * @param dataclickarea
 * @param dataclickname
 */
export const getFAQProps = (dataclickarea: string, { dataclickname = '' } = {}) => {
  return {
    dataclick: 'faqClick',
    dataclickarea: dataclickarea,
    dataclickname: dataclickarea ? dataclickname : dataclickarea,
    datachannel: mobileCheck() ? 'Mobile' : 'PC',
  };
};

/**
 * (GA) QNA 영역
 * @param title
 */
export const getQnaProps = (title?: string) => {
  return {
    dataclick: 'ask',
    dataclickname: title,
    datachannel: mobileCheck() ? 'Mobile' : 'PC',
  };
};

/**
 *  (GA) 로그인 버튼 클릭
 * - dataclick : 이벤트 명 (=logins)
 * - datachannel : 클릭 채널 (=PC or App)
 * - member_affiliate : 계열사 명
 */
export const getGALoginClickProps = ({ dataclick = 'login', memberAffiliate = 'LG' } = {}) => {
  return {
    dataclick: String(dataclick || 'login'),
    member_affiliate: String(memberAffiliate || 'LG'),
    datachannel: String(mobileCheck() ? 'Mobile' : 'PC' || 'PC'),
  };
};

/**
 * 미디어 컨텐츠 영역 클릭 (홈 메인/상세)
 *
 * @param contentsType
 * @param title
 * @param tags
 * @param category
 * @param dataContentBelong
 * @param dataContentType
 * @param maker
 * @param time
 * @param brightcoveId
 * @param mediaContentsType
 * @param contentsDetailType
 * @param dataContentUrl
 * @param dataimgurl
 * @param dataclick
 */
export const getContentsGAProps = (
  {
    contentsType,
    title,
    tags,
    category,
    dataContentBelong,
    dataContentType,
    maker,
    time,
    brightcoveId,
    mediaContentsType,
    contentsDetailType,
    dataContentUrl,
    dataImgUrl,
  }: {
    contentsType: string;
    title: string;
    tags: string;
    category: string;
    dataContentBelong: string;
    dataContentType?: string;
    maker: string;
    time: number | undefined;
    brightcoveId: string;
    mediaContentsType: string;
    contentsDetailType?: string;
    dataContentUrl: string;
    dataImgUrl: string;
  },
  { dataclick = '' } = {}
) => {
  return {
    dataclick: dataclick ? dataclick : 'contentsClick',
    datacontenttype: dataContentType
      ? dataContentType
      : getGAContentType({
          contentType: contentsType,
          mediaContentsType: mediaContentsType,
          contentsDetailType: contentsDetailType,
        }),
    datacontentname: title,
    datacontenttags: tags ? tags.substring(1).replace(/#/g, '_') : '',
    datacontentcategory: category,
    datacontentbelong: dataContentBelong,
    datacontentmaker: maker,
    datacontentlen: mediaContentsType === 'VIDEO' ? millisToMinutesAndSeconds(time) : '',
    brightcoveid: brightcoveId,
    datacontenturl: dataContentUrl,
    dataimgurl: dataImgUrl,
    datachannel: mobileCheck() ? 'Mobile' : 'PC',
  };
};

/**
 * (GA) 이벤트 영역 클릭
 *
 * @param type
 * @param url
 * @param title
 * @param belong
 * @param status
 * @param dataImgUrl
 * @param dataContentType
 */
export const getEventGAProps = ({
  type = 'EDITOR',
  url,
  title,
  dataContentBelong,
  status = 'ING',
  dataImgUrl,
  dataContentType,
}: {
  type?: string;
  url?: string;
  title?: string;
  dataContentBelong?: string;
  status?: string;
  dataImgUrl?: string;
  dataContentType?: string;
}) => {
  return {
    dataclick: 'contentsClick',
    datacontenttype: dataContentType ? dataContentType : getGAEventMenuName(type),
    datacontentbelong: dataContentBelong,
    datacontenturl: url,
    datacontentname: title,
    eventstatus: status === 'END' ? '종료' : '진행중',
    dataimgurl: dataImgUrl,
    datachannel: mobileCheck() ? 'Mobile' : 'PC',
  };
};

/**
 * (GA) 유용한 모음 클릭
 *
 * @param dataclickarea
 * @param dataclickname
 * @param dataclicksns
 */
export const getLGLifeToolsGAProps = ({
  dataclickarea = '유용한 모음',
  dataclickname = '',
  dataclicksns = '',
} = {}) => {
  return {
    dataclick: 'toolsClick',
    datachannel: mobileCheck() ? 'Mobile' : 'PC',
    dataclickarea: dataclickarea || '',
    dataclickname: dataclickname ? dataclickname : dataclickarea || '',
    dataclicksns: dataclicksns ? dataclicksns : '',
  };
};

/**
 * (GA) 커뮤니티 게시판
 * @param type
 * @param title
 * @param category
 * @param dataContentBelong 대메뉴
 * @param dataContentType 세부 메뉴
 * @param maker
 * @param postAffiliate
 * @param dataclick
 */
export const getBoardGAProps = (
  {
    boardType,
    title,
    category,
    subCategory,
    dataContentBelong,
    dataContentType,
    maker,
    postAffiliate = '',
    dataContentUrl = '',
  }: {
    boardType?: string;
    title?: string;
    category?: string;
    subCategory?: string;
    dataContentBelong?: string;
    dataContentType?: string;
    maker?: string;
    postAffiliate?: string;
    dataContentUrl?: string;
  },
  { dataclick = '' } = {}
) => {
  return {
    dataclick: dataclick,
    datacontenttype: dataContentType ? dataContentType : getGACommunityBoardMenuName(boardType as string),
    datacontentname: title,
    datacontentcategory: category, // 중고거래 등
    datacontentbelong: dataContentBelong,
    datacontentmaker: maker,
    datachannel: mobileCheck() ? 'Mobile' : 'PC',
    postaffiliate: postAffiliate,
    datacontenturl: dataContentUrl,
  };
};

/**
 * (GA) 댓글 작성 시, GA 호출
 * @param commentType
 * @param tags
 * @param type
 * @param name
 * @param time
 * @param category
 * @param creatorNm
 * @param brightcoveId
 * @param eventType
 * @param magazineType
 * @param eventStatus
 * @param mediaContentsType
 * @param groupNm
 * @param dataContentUrl
 */
export const getCommentGAProps = ({
  commentType,
  tags,
  type,
  name,
  time,
  category,
  creatorNm,
  brightcoveId,
  eventType,
  magazineType,
  eventStatus,
  mediaContentsType,
  contentsDetailType,
  groupNm,
  dataContentUrl,
}: {
  commentType: string;
  tags: string;
  type: string;
  name: string;
  time: number;
  category: string;
  creatorNm: string;
  brightcoveId: string;
  eventType?: string;
  magazineType?: string;
  eventUrl?: string;
  eventStatus?: string;
  mediaContentsType?: string;
  contentsDetailType?: string;
  groupNm?: string;
  dataContentUrl?: string;
}) => {
  if (type === 'EVENT' || EVENT_MENU_INFO_LIST.some(item => item.code === type)) {
    // 이벤트
    return {
      dataclick: 'comment',
      dataclickname: commentType === '답글' ? '답글' : '댓글', // commentType = '댓글' | '답글' | 'emoji',
      dataclickarea: '코멘트',
      datacontenttype: getGAEventMenuName(eventType as string),
      datacontentbelong: '이벤트',
      datacontentname: name,
      eventstatus: eventStatus === 'ING' ? '진행중' : '종료',
      datacontenturl: dataContentUrl,
      datachannel: mobileCheck() ? 'Mobile' : 'PC',
    };
  }

  if (COMMUNITY_BOARD_MENU_INFO_LIST.some(item => item.code === type)) {
    // 커뮤니티 게시판
    return {
      dataclick: 'boardComment',
      dataclickname: commentType === '답글' ? '답글' : '댓글', // commentType = '댓글' | '답글' | 'emoji'
      dataclickarea: '코멘트',
      datacontenttype: getGACommunityBoardMenuName(type),
      datacontentbelong: '커뮤니티',
      datacontenttags: tags ? tags.substring(1).replace(/#/g, '_') : '',
      datacontentname: name,
      datacontentcategory: category, //{콘텐츠카테고리 Admin에서 정의된 값}
      datacontentmaker: creatorNm, //{댓글 작성자 이름}
      postaffiliate: groupNm,
      datacontenturl: dataContentUrl,
      datachannel: mobileCheck() ? 'Mobile' : 'PC',
    };
  }

  // 영상 / 뉴스 등
  return {
    dataclick: 'comment',
    dataclickname: commentType === '답글' ? '답글' : '댓글', // commentType = '댓글' | '답글' | 'emoji'
    dataclickarea: '코멘트',
    datacontenttype: getGAContentType({
      contentType: type,
      mediaContentsType: mediaContentsType as string,
      contentsDetailType: contentsDetailType as string,
      magazineType: magazineType || '',
    }),
    datacontentbelong: '콘텐츠',
    datacontenttags: tags ? tags.substring(1).replace(/#/g, '_') : '',
    datacontentname: name,
    datacontentlen: time >= 0 ? millisToMinutesAndSeconds(time) : '', //영상길이 00:00(분:초) 영상 외 컨텐츠는 ''으로 처리
    datacontentcategory: category, //{콘텐츠카테고리 Admin에서 정의된 값}
    datacontentmaker: creatorNm, //{댓글 작성자 이름}
    brightcoveid: brightcoveId,
    datacontenturl: dataContentUrl,
    datachannel: mobileCheck() ? 'Mobile' : 'PC',
  };
};

/**
 * (GA) 태그 검색
 * @param searchType
 * @param tagName
 */
export const getContentTagGAProps = (searchType: string, tagName: string) => {
  return {
    dataclick: 'search',
    datasearchtype: searchType,
    dataclickname: tagName,
    datachannel: mobileCheck() ? 'Mobile' : 'PC',
  };
};

/**
 * (GA) 컨텐츠 좋아요/찜/다운로드/공유 등 옵션 버튼 클릭
 * @param buttonType
 * @param contentType
 * @param magazineType
 * @param tags
 * @param title
 * @param time
 * @param category
 * @param creatorNm
 * @param brightcoveId
 * @param mediaContentsType
 * @param dataContentBelong 대메뉴 명
 * @param dataContentType 세부 메뉴 명
 */
export const getContentsButtonGAProps = ({
  buttonType,
  contentType,
  magazineType,
  tags,
  title,
  time,
  dataContentCategory,
  creatorNm,
  brightcoveId,
  mediaContentsType,
  dataContentBelong,
  dataContentType,
}: {
  buttonType: string;
  contentType: string;
  magazineType?: string;
  tags: string;
  title: string;
  time: number;
  dataContentCategory: string;
  creatorNm: string;
  brightcoveId: string;
  mediaContentsType: string;
  dataContentBelong?: string;
  dataContentType?: string;
}) => {
  return {
    dataclick: 'optionClick',
    dataclickname: buttonType, // 좋아요|찜|원고|다운로드|공유
    dataclickarea: '콘텐츠추가정보',
    datacontentbelong: dataContentBelong || '콘텐츠',
    datacontenttype: dataContentType
      ? dataContentType
      : getGAContentType({
          contentType: contentType,
          mediaContentsType: mediaContentsType || '영상',
          magazineType: magazineType || '',
        }),
    datacontenttags: tags ? tags.substring(1).replace(/#/g, '_') : '',
    datacontentname: title,
    datacontentlen: time >= 0 ? millisToMinutesAndSeconds(time) : '', //영상길이 00:00(분:초) 영상 외 컨텐츠는 ''으로 처리
    datacontentcategory: dataContentCategory, //{콘텐츠카테고리 Admin에서 정의된 값}
    datacontentmaker: creatorNm, //{콘텐츠제작자}
    brightcoveid: brightcoveId,
    datachannel: mobileCheck() ? 'Mobile' : 'PC',
  };
};

/**
 * (GA) 연관 컨텐츠
 * @param area
 * @param contentsType
 * @param title
 * @param time
 * @param isVideo
 * @param category
 * @param belong
 * @param maker
 * @param tags
 * @param brightcoveId
 * @param mediaContentsType
 */
export const getRelatedContentsGAProps = ({
  area,
  contentsType,
  title,
  time,
  isVideo,
  category,
  belong,
  maker,
  tags,
  brightcoveId,
  mediaContentsType,
}: {
  area?: string;
  contentsType?: string;
  title?: string;
  time?: number;
  isVideo: boolean;
  category?: string;
  belong?: string;
  maker?: string;
  tags?: string;
  brightcoveId?: string;
  mediaContentsType: string;
}) => {
  return {
    dataclick: 'contentsClick',
    dataclickarea: area || '콘텐츠추가탐색',
    datacontenttype: getGAContentType({
      contentType: contentsType || 'MEDIA',
      mediaContentsType: mediaContentsType || '영상',
    }),
    datacontentname: title || '',
    datacontentlen: time && isVideo ? millisToMinutesAndSeconds(time) : '',
    datacontentcategory: category || '',
    datacontentbelong: belong || '',
    datacontentmaker: maker || '',
    datacontenttags: tags || '',
    brightcoveid: brightcoveId || '',
    datachannel: mobileCheck() ? 'Mobile' : 'PC',
  };
};

/**
 * (GA) 설문 이벤트
 * @param dataClick
 * @param eventType
 * @param dataClickArea
 * @param dataClickName
 * @param title
 * @param url
 * @param eventStatus
 */
export const getGAEventSubmenuSubmitProps = ({
  dataClick = 'surveySubmit',
  contentsName = 'LG인 설문 조사',
  dataClickArea = '설문 제출',
  dataClickName = '설문 제출',
  title,
  url,
  eventStatus = 'END',
}: {
  dataClick: string;
  contentsName: string;
  dataClickArea: string;
  dataClickName: string;
  title: string;
  url: string;
  eventStatus: string;
}) => {
  return {
    dataclick: dataClick,
    dataclickarea: dataClickArea,
    dataclickname: dataClickName,
    datacontenttype: contentsName,
    datacontentname: title,
    datacontenturl: url,
    eventstatus: eventStatus === 'ING' ? '진행중' : '종료',
    datachannel: mobileCheck() ? 'Mobile' : 'PC',
  };
};

/**
 * (GA) EP 클릭 시, GA 호출
 * @param name
 * @param area
 */
export const getGAEpProps = (name: string, area: string) => {
  return {
    dataclick: 'epClick',
    dataclickname: getHtmlFormatByEscapeHtmlData(name || '') as string,
    dataclickarea: getHtmlFormatByEscapeHtmlData(area || '') as string,
    datachannel: mobileCheck() ? 'Mobile' : 'PC',
  };
};

/* *********************************************************************** *
 * (GA) 셋팅 된 attribute 값을 이벤트 발생 시, dataLayer push
 * ********************************************************************** */

/**
 * (GA) GA 호출 (target 의 attribute 를 가져 와서, 매핑 하여 push)
 * - 이벤트 호출 시, GA 호출 하도록 하기 위해 사용
 * @param e 이벤트 currentTarget
 */
export const all_click_datalayer_push = (e: any) => {
  window.dataLayer = window.dataLayer || [];
  if (!e) return;
  const clickSel = e;
  const dataClickValue = clickSel?.getAttribute('dataClick') || '';

  if (!dataClickValue) return;

  const commonContentAttributeList = {
    dataClickArea: clickSel.getAttribute('dataClickArea'),
    dataClickName: clickSel.getAttribute('dataClickName'),
    dataContentType: clickSel.getAttribute('dataContentType'),
    dataContentTags: clickSel.getAttribute('dataContentTags'),
    dataContentName: clickSel.getAttribute('dataContentName'),
    dataContentLen: clickSel.getAttribute('dataContentLen'),
    dataContentCategory: clickSel.getAttribute('dataContentCategory'),
    dataContentBelong: clickSel.getAttribute('dataContentBelong'),
    dataContentMaker: clickSel.getAttribute('dataContentMaker'),
    brightCoveId: clickSel.getAttribute('brightCoveId'),
    dataContentUrl: clickSel.getAttribute('dataContentUrl'),
    dataImgUrl: clickSel.getAttribute('dataImgUrl'),
    eventStatus: clickSel.getAttribute('eventStatus'),
    dataChannel: clickSel.getAttribute('datachannel'),
  };

  const oldGaUaCommonContentAttributeList = {
    dataEventType: clickSel.getAttribute('dataEventType'),
    dataEventURL: clickSel.getAttribute('dataEventURL'),
    dataEventName: clickSel.getAttribute('dataEventName'),
  };

  // 분기 처리
  switch (dataClickValue) {
    case 'faqClick':
      window.dataLayer.push({
        event: 'customFaqClick',
        clickPage: window.ga_object.Page.page_name,
        dataClickArea: clickSel.getAttribute('dataClickArea'),
        dataClickName: clickSel.getAttribute('dataClickName'),
        dataChannel: clickSel.getAttribute('datachannel'),
      });
      break;
    case 'toolsClick':
      window.dataLayer.push({
        event: 'customToolsClick',
        clickPage: window.ga_object.Page.page_name,

        dataClickArea: clickSel.getAttribute('dataClickArea'),
        dataClickName: clickSel.getAttribute('dataClickName'),
        dataChannel: clickSel.getAttribute('datachannel'),
        dataClickSNS: clickSel.getAttribute('dataClickSNS'),
      });
      break;
    case 'boardClick':
      window.dataLayer.push({
        event: 'customBoardClick',
        clickPage: window.ga_object.Page.page_name,

        dataClickArea: clickSel.getAttribute('dataClickArea'),
        dataClickName: clickSel.getAttribute('dataClickName'),
        dataContentName: clickSel.getAttribute('dataContentName'),
        dataContentType: clickSel.getAttribute('dataContentType'),
        dataContentCategory: clickSel.getAttribute('dataContentCategory'),
        dataContentBelong: clickSel.getAttribute('dataContentBelong'),
        dataContentMaker: clickSel.getAttribute('dataContentMaker'),
        postAffiliate: clickSel.getAttribute('postAffiliate'),
        dataChannel: clickSel.getAttribute('datachannel'),
        dataContentUrl: clickSel.getAttribute('dataContentUrl'),
      });
      break;
    case 'menuClick':
      window.dataLayer.push({
        event: 'customMenuClick',
        clickPage: window.ga_object.Page.page_name,

        dataClickArea: clickSel.getAttribute('dataClickArea'),
        dataClickName: clickSel.getAttribute('dataClickName'),
        dataChannel: clickSel.getAttribute('datachannel'),
        menuName: clickSel.getAttribute('dataClickName'),
      });
      break;
    case 'contentsClick':
      // --[E] [UA --
      window.dataLayer.push({
        event: 'customContentsClick',
        clickPage: window.ga_object.Page.page_name,

        ...commonContentAttributeList,

        // [UA]
        ...oldGaUaCommonContentAttributeList,
      });

      // --[S] [UA] --
      if (clickSel.getAttribute('dataContentBelong') === '이벤트') {
        window.dataLayer.push({
          event: 'customEventClick',
          clickPage: window.ga_object.Page.page_name,

          ...commonContentAttributeList,
        });
        break;
      }
      break;
    case 'optionClick':
      // GA4
      window.dataLayer.push({
        event: 'customOptionClick',
        clickPage: window.ga_object.Page.page_name,

        ...commonContentAttributeList,
      });

      // --[S] [UA] --
      if (clickSel.getAttribute('dataClickName') === '좋아요') {
        window.dataLayer.push({
          event: 'likeClick',
          clickPage: window.ga_object.Page.page_name,

          ...commonContentAttributeList,
        });
        break;
      }
      // --[E] [UA] --
      // --[S] [UA] --
      if (clickSel.getAttribute('dataClickName') === '찜') {
        window.dataLayer.push({
          event: 'dibClick',
          clickPage: window.ga_object.Page.page_name,

          ...commonContentAttributeList,
        });
        break;
      }
      // --[E] [UA] --
      // --[S] [UA] --
      if (clickSel.getAttribute('dataClickName') === '원고') {
        window.dataLayer.push({
          event: 'documentClick',
          clickPage: window.ga_object.Page.page_name,

          ...commonContentAttributeList,
        });
        break;
      }
      // --[E] [UA] --
      // --[S] [UA] --
      if (clickSel.getAttribute('dataClickName') === '다운로드') {
        window.dataLayer.push({
          event: 'downloadClick',
          clickPage: window.ga_object.Page.page_name,

          ...commonContentAttributeList,
        });
        break;
      }
      // --[E] [UA] --
      // --[S] [UA] --
      if (clickSel.getAttribute('dataClickName') === '공유') {
        window.dataLayer.push({
          event: 'shareClick',
          clickPage: window.ga_object.Page.page_name,

          ...commonContentAttributeList,
        });
        break;
      }
      // --[E] [UA] --
      break;
    case 'click':
      window.dataLayer.push({
        event: 'customClick',
        clickPage: window.ga_object.Page.page_name,

        ...commonContentAttributeList,
      });
      break;
    case 'comment':
      // --[S] [UA] --
      if (window.ga_object.Page.page_name === 'Event/Detail Page') {
        window.dataLayer.push({
          event: 'customEventCommentWrite',
          clickPage: window.ga_object.Page.page_name,

          ...commonContentAttributeList,

          // [UA]
          ...oldGaUaCommonContentAttributeList,
        });
        break;
      }

      // GA4
      window.dataLayer.push({
        event: 'customCommentWrite',
        clickPage: window.ga_object.Page.page_name,

        ...commonContentAttributeList,

        dataContentUrl: window.ga_object.Page.page_URL.replace(/^https?:\/\/[^/]+\//, ''),
      });
      break;
    case 'search':
      const tempsk = clickSel.getAttribute('search_term') || '';
      let finalsk = '';

      if (tempsk && tempsk.indexOf('#') > -1) {
        finalsk = tempsk.split('#')?.[1];
      } else {
        finalsk = tempsk;
      }

      window.dataLayer.push({
        event: 'customSearch',
        search_type: clickSel.getAttribute('search_type'),
        search_term: String(finalsk),
        dataChannel: clickSel.getAttribute('datachannel'),
      });
      break;
    case 'alarmClick':
      if (window.ga_object.Page.page_name === 'EP Panel') {
        window.dataLayer.push({
          event: 'customEPClick',
          clickPage: window.ga_object.Page.page_name,
          dataClickName: clickSel.getAttribute('dataclickname'),
          dataClickArea: 'alarm',
          dataChannel: clickSel.getAttribute('datachannel'),
        });
        break;
      }

      window.dataLayer.push({
        event: 'customAlarmClick',
        dataClickChannel: clickSel.getAttribute('datachannel'),

        dataClickArea: clickSel.getAttribute('dataclickarea'),
        dataClickName: clickSel.getAttribute('dataclickname'),
        dataChannel: clickSel.getAttribute('datachannel'),
      });
      break;
    case 'epClick':
      window.dataLayer.push({
        event: 'customEPClick',
        clickPage: window.ga_object.Page.page_name,
        dataClickArea: clickSel.getAttribute('dataclickarea'),
        dataClickName: clickSel.getAttribute('dataclickname'),
        dataChannel: clickSel.getAttribute('datachannel'),
      });
      break;
    case 'memberSearch':
      window.dataLayer.push({
        event: 'customMemberSearch',
        dataClickArea: clickSel.getAttribute('dataclickarea'),
        dataClickName: clickSel.getAttribute('dataclickname'),
        dataChannel: clickSel.getAttribute('datachannel'),
        clickPage: window.ga_object.Page.page_name,
      });
      break;
    case 'boardComment':
      window.dataLayer.push({
        event: 'customBoardCommentWrite',
        clickPage: window.ga_object.Page.page_name,

        dataClickArea: clickSel.getAttribute('dataClickArea'),
        dataClickName: clickSel.getAttribute('dataClickName'),
        dataChannel: clickSel.getAttribute('datachannel'),
        dataContentName: clickSel.getAttribute('dataContentName'),
        dataContentType: clickSel.getAttribute('dataContentType'),
        dataContentTags: clickSel.getAttribute('dataContentTags'),
        dataContentCategory: clickSel.getAttribute('dataContentCategory'),
        dataContentBelong: clickSel.getAttribute('dataContentBelong'),
        dataContentLen: clickSel.getAttribute('dataContentLen'),
        dataContentMaker: clickSel.getAttribute('dataContentMaker'),
        postAffiliate: clickSel.getAttribute('postAffiliate'),
        dataContentUrl: window.ga_object.Page.page_URL.replace(/^https?:\/\/[^/]+\//, ''),
      });
      break;
    case 'post':
      window.dataLayer.push({
        event: 'customNewWrite',
        clickPage: window.ga_object.Page.page_name,
        dataContentName: clickSel.getAttribute('dataContentName'),
        dataContentCategory: clickSel.getAttribute('dataContentCategory'),
        dataChannel: clickSel.getAttribute('datachannel'),
        postAffiliate: clickSel.getAttribute('postAffiliate'),
      });
      break;
    case 'login':
      window.dataLayer.push({
        ...commonContentAttributeList,
        clickPage: window.ga_object.Page.page_name,
        member_affiliate: clickSel.getAttribute('member_affiliate'),
      });
  }

  const remainData = (Object.values(clickSel)?.[Object.keys(clickSel)?.length - 1] as Object) || ({} as Object);

  window.dataLayer.push({
    event: clickSel.getAttribute('dataClick'),
    clickPage: window.ga_object.Page.page_name,
    dataClick: clickSel.getAttribute('dataClick'),
    dataClickArea: clickSel.getAttribute('dataClickArea'),
    dataClickName: clickSel.getAttribute('dataClickName'),
    dataChannel: clickSel.getAttribute('datachannel'),
    ...remainData,
  });

  fetchGAInterfaceToLocalStorage();
};

/* *********************************************************************** *
 * (GA) 타이밍 등 이슈로 인해 직접 dataLayer push
 * ************************************************************************ */

/**
 * (GA) 페이지 뷰 이벤트
 */
export const callGAEvent = () => {
  if (window.last_ga_object === JSON.stringify(window.ga_object)) {
    return;
  }

  // -- LG CNS에서 타이밍 이슈로 전달한 코드 ---
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'customPageView',
  });
  // -- LG CNS에서 전달한 코드 ---

  window.last_ga_object = JSON.stringify(window.ga_object);
  fetchGAInterfaceToLocalStorage();
};

/**
 * (GA) 검색 이벤트 시, GA 호출
 * @param keyword 검색어
 * @param searchType 검색 대상
 * @param options 옵션
 */
export const callGASearchEvent = ({
  keyword,
  searchType,
  options,
}: {
  /** 검색어 */
  keyword: string;
  /** 검색 타입 */
  searchType: 'tagSearch' | 'mainSearch';
  /** 옵션 */
  options?: {
    dataClick?: string;
    dataClickArea?: string;
    dataClickName?: string;
  };
}) => {
  window.dataLayer = window.dataLayer || [];
  const _paramData = {
    event: 'customSearch',
    dataClick: options?.dataClick ? options.dataClick : 'search',

    search_type: searchType === 'tagSearch' ? '인기 키워드' : '일반 검색',
    search_term:
      keyword?.length > 0 && keyword?.substring(0, 1) === '#' ? keyword.substring(1, keyword.length) : keyword || '',
    dataChannel: mobileCheck() ? 'Mobile' : 'PC',
  };

  window.dataLayer.push(_paramData);
  fetchGAInterfaceToLocalStorage();
};

/**
 * (GA) 회원 가입 시, GA 이벤트 호출
 * @param job 직무
 * @param position 직위 / 직급
 * @param genderCode 성별 코드 ('M', 'F', 'NONE')
 * @param birthYmd 생년월일
 * @param companyName 계열사 명
 * @param options
 */
export const callGASignUpEvent = ({
  job,
  position,
  genderCode,
  birthYmd,
  companyName,
  options,
}: {
  /** 직무 */
  job?: string;
  /** 직위 / 직급 */
  position?: string;
  /** 성별 코드 */
  genderCode?: 'M' | 'F' | 'NONE';
  /** 생년월일 */
  birthYmd?: string;
  /** 계열사 명 */
  companyName: string;
  /** 옵션 */
  options?: {
    dataClick?: string;
    dataClickArea?: string;
    dataClickName?: string;
  };
}) => {
  window.dataLayer = window.dataLayer || [];
  const signUpParam = {
    event: 'customSignUp',
    dataClick: options?.dataClick ? options.dataClick : 'signUp',
    signupAffiliate: companyName || '',
    member_job: job || '',
    member_position: position || '',
    member_affiliate: companyName || '',
    member_gender: getGAGenderName(genderCode) || '',
    member_age_group: getGAAgeGroupFromBirthYmd(birthYmd || '') || 'U',
    dataChannel: mobileCheck() ? 'Mobile' : 'PC',
  };

  window.dataLayer.push(signUpParam);
  fetchGAInterfaceToLocalStorage();
};

/**
 * (GA) 메뉴 GA 이벤트 직접 push
 * @param dataClickArea
 * @param dataClickName
 */
export const callMenuGAEvent = (dataClickArea, { dataClickName = '' } = {}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: dataClickArea || 'customMenuClick',
    dataClickArea: dataClickArea || '',
    dataClickName: dataClickName || dataClickArea || '',
    dataChannel: mobileCheck() ? 'Mobile' : 'PC',
    clickPage: window.ga_object.Page.page_name,
  });
  fetchGAInterfaceToLocalStorage();
};

/**
 * (GA) 1대1 문의하기(QNA) 등록 push
 * @param askType
 */
export const callQnaWriteGaEvent = ({ askType }: { askType: string }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'customAskWrite',
    dataChannel: mobileCheck() ? 'Mobile' : 'PC',
    askType: askType,
  });
  fetchGAInterfaceToLocalStorage();
};

/**
 * (GA) 영상 시청 종료 GA 이벤트 push
 * @param gaEventType
 * @param dataContentType
 * @param title
 * @param cateNm
 * @param maker
 * @param dataContentsLength
 * @param brightCoveId
 */
export const callVidePlayGaEvent = ({
  gaEventType,
  dataContentType,
  title,
  cateNm,
  creatorNm,
  dataContentsLength,
  brightCoveId,
}: {
  gaEventType: string;
  dataContentType: string;
  title: string;
  cateNm: string;
  creatorNm: string;
  dataContentsLength: string;
  brightCoveId: string;
}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: gaEventType || 'customVideoDone',
    dataContentType: dataContentType,
    dataContentName: title,
    dataContentLen: dataContentsLength,
    dataContentCategory: cateNm,
    dataContentMaker: creatorNm,
    dataContentBrightCoveID: brightCoveId,
    dataChannel: mobileCheck() ? 'Mobile' : 'PC',
  });
  fetchGAInterfaceToLocalStorage();
};

/**
 * (GA) 자유 게시판 게시글 등록 GA 이벤트 Push
 * @param type
 * @param title
 * @param category
 * @param belong
 * @param maker
 * @param dataContentUrl
 * @param selectedGroupNm
 */
export const callGABoardWrite = ({
  type,
  title,
  categoryNm,
  belong,
  maker,
  dataContentUrl,
  selectedGroupNm,
}: {
  type: string;
  title: string;
  categoryNm: string;
  belong: string;
  maker: string;
  dataContentUrl: string;
  selectedGroupNm: string;
}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    dataClick: 'post',
    event: 'customNewWrite',
    clickPage: window.ga_object.Page.page_name,
    dataContentName: title,
    dataContentCategory: categoryNm,
    postAffiliate: selectedGroupNm,
    dataContentType: type,
    dataContentBelong: belong,
    dataContentMaker: maker,
    dataChannel: mobileCheck() ? 'Mobile' : 'PC',
    dataContentUrl: dataContentUrl,
  });
  fetchGAInterfaceToLocalStorage();
};
