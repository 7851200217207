import { endpoints, fetcher } from 'services';
import useSWR from 'swr';
import useMyInfo from './useMyInfo';
import { useCallback } from 'react';
import { LNBMenuType } from '../types/enum/LNBMenuType';

/**
 * NEW 뱃지 확인 위한 LNB 메뉴 목록
 */
const displayNewBadgeStatusReqParams: DisplayNewBadgeStatusListReqDto = {
  displayNewBadgeStatusReqDtoList: [
    /** 간단 설문 */
    {
      dayTerms: 7,
      menuType: LNBMenuType.SIMPLE_SURVEY,
    },
    // /** 영상 */
    // {
    //   dayTerms: 7,
    //   menuType: LNBMenuType.VIDEO,
    // },
    // /** 상시 이벤트 */
    // {
    //   dayTerms: 7,
    //   menuType: LNBMenuType.EDITOR,
    // },
  ],
};

const useDisplayNewBadgeStatus = () => {
  const { info } = useMyInfo();

  const { data: responseDate } = useSWR<DisplayNewBadgeStatusListResDto>(
    info ? [endpoints.common.displayNewBadgeStatus] : null,
    () => fetcher.common.displayNewBadgeStatus(displayNewBadgeStatusReqParams)
  );

  /**
   * New 뱃지 여부 조회
   * @return
   */
  const getNewBadgeMenuInfo = useCallback(
    (menuType: LNBMenuType) => {
      if (!responseDate) return;
      return responseDate.displayNewBadgeStatusResDtoList.find(item => item.menuType === menuType);
    },
    [responseDate]
  );

  return {
    getNewBadgeMenuInfo,
  };
};

export default useDisplayNewBadgeStatus;
