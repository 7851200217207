import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CardRecord from 'components/atoms/CardRecord';
import ProfileImage from 'components/atoms/ProfileImage';
import { dateYYYYMMDD } from 'utils/utils';
import { all_click_datalayer_push, getBoardGAProps } from 'utils/GAUtils';
import Paths from 'commons/paths';
import dompurify from 'dompurify';
import { getHtmlFormatByEscapeHtmlData } from '../../../../../utils/LGLifeUtils';

export interface DepartmentBoardCardProps extends DepartmentBoardCardResDto {
  fixedLine?: boolean;
  className?: string;
}

const DepartmentBoardCard = (props: DepartmentBoardCardProps) => {
  const [line, setLine] = useState<string>();

  useEffect(() => {
    if (props.className) {
      setLine(props.className);
    } else {
      setLine(`ui-line-${Math.floor(Math.random() * 3 + 4)}`);
    }
  }, [props.className]);

  return (
    <>
      <Link
        to={Paths.getPath('departmentBoardDetail').replace(':boardId', props.boardId as string)}
        className="card"
        style={{ width: '100%' }}
        {...getBoardGAProps(
          {
            boardType: props.boardType,
            title: getHtmlFormatByEscapeHtmlData(props.title as string),
            category: props.cateNm,
            dataContentBelong: '커뮤니티',
            dataContentType: '회사 속 직무',
            maker: props.creatorNm,
            postAffiliate: props.creatorGroupNm,
            dataContentUrl: `${Paths.getPath('departmentBoardDetail').replace(':boardId', `${props.boardId}`)}`,
          },
          {
            dataclick: 'boardClick',
          }
        )}
        onClick={e => {
          all_click_datalayer_push(e.currentTarget);
        }}
      >
        <div className="card_note">
          <div className="card_note_top">
            <span className="card_note_category">{props.cateNm}</span>
            {!!props.subCateNm && <span className="card_note_head">{props.subCateNm}</span>}
          </div>
          {/* <!-- 컨텐츠 내용 줄 수는 랜덤으로 할당 --> */}
          <div className={`card_note_con ${props.fixedLine ? '' : line}`}>
            <div className="note_title">{getHtmlFormatByEscapeHtmlData(props.title as string)}</div>
            <div
              className="note_desc"
              dangerouslySetInnerHTML={{
                __html:
                  !props.contents || props.contents.trim().length === 0
                    ? '텍스트가 없는 게시물 입니다.'
                    : dompurify.sanitize(props.contents),
              }}
            ></div>
            <div className="note_sub">
              <CardRecord
                views={props.readCnt as number}
                comments={props.commentCnt as number}
                likes={props.likeCnt as number}
              />
            </div>
            <div className="note_profile">
              <div className="note_profile_name">
                <ProfileImage
                  score={props.creatorActScore as number}
                  name={props.creatorNm || ''}
                  image={props.creatorProfileUri}
                  isAdmin={props.creatorAdminYn === 'Y'}
                />
                {props.creatorAdminYn === 'Y'
                  ? '관리자'
                  : `${props.creatorNm} ${props.creatorRetireYn === 'N' ? props.creatorGroupNm : ''}`}
              </div>
              <div className="note_profile_date">{dateYYYYMMDD(props.createdDt)}</div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export const DepartmentBoardListItem = React.memo((props: DepartmentBoardCardProps) => {
  return (
    <>
      <td className="list_cell cell_tag">
        <span className="card_thumb_flag is-cate">{props.cateNm}</span>
      </td>
      <td className="list_cell">{!!props.subCateNm && <span className="list_text_cate">{props.subCateNm}</span>}</td>
      <td className="list_cell cell_writer">
        <ProfileImage
          name={props.creatorNm || ''}
          score={props.creatorRetireYn === 'N' ? (props.creatorActScore as number) : 0}
          image={props.creatorProfileUri}
          isAdmin={props.creatorAdminYn === 'Y'}
        />
        <span className="list_writer">
          {props.creatorAdminYn === 'Y'
            ? '관리자'
            : `${props.creatorNm} ${props.creatorGroupNm && props.creatorRetireYn === 'N' ? props.creatorGroupNm : ''}`}
        </span>
      </td>
      <td className="list_cell cell_title">
        <Link
          to={`${Paths.getPath('departmentBoardDetail').replace(':boardId', `${props.boardId}`)}`}
          className="list_title_link"
          {...getBoardGAProps(
            {
              boardType: props.boardType,
              title: getHtmlFormatByEscapeHtmlData(props.title || ''),
              category: props.cateNm,
              dataContentBelong: '커뮤니티',
              dataContentType: '회사 속 직무',
              maker: props.creatorNm,
              postAffiliate: props.creatorGroupNm,
              dataContentUrl: `${Paths.getPath('departmentBoardDetail').replace(':boardId', `${props.boardId}`)}`,
            },
            {
              dataclick: 'boardClick',
            }
          )}
          onClick={e => {
            all_click_datalayer_push(e.currentTarget);
          }}
        >
          <span className="list_title">{getHtmlFormatByEscapeHtmlData(props.title || '')}</span>
        </Link>
      </td>
      <td className="list_cell cell_small">
        <img src="/assets/images/icon/ico-action-20-view.svg" className="list_text_icon" alt="views" />
        <span className="list_text list_view">{props.readCnt}</span>
      </td>
      <td className="list_cell cell_small">
        <img src="/assets/images/icon/ico-comments.png" className="list_text_icon" alt="comments" />
        <span className="list_text list_comments">{props.commentCnt}</span>
      </td>
      <td className="list_cell cell_small">
        <img src="/assets/images/icon/ico-likes.png" className="list_text_icon" alt="likes" />
        <span className="list_text list_likes">{props.likeCnt}</span>
      </td>
      <td className="list_cell">
        <div className="list_date">{dateYYYYMMDD(props.createdDt)}</div>
      </td>
    </>
  );
});

DepartmentBoardCard.displayName = 'DepartmentBoardCard';

export default DepartmentBoardCard;
