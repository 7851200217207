import request from 'services/request';
import { endpoints } from 'services/endpoints';
import { paramsToFormData } from 'utils/utils';
import { PagingReqDto } from '../../types';

export interface TopEatsBoardListRequest extends PagingReqDto {
  /** 근무지 목록(카테고리 목록) */
  cateList?: string;
  /** 음식 리스트 */
  subCateList?: string;
  /** 게시판 유형 */
  boardType?: string;
  /** 상단 통함 검색어 */
  topSearchWord?: string;
  /** 게시판 내 검색어 */
  searchWordIn?: string;
  /** 예약 가능 여부 */
  reserveYn?: 'Y' | 'N';
  /** 재방문 의사 여부 */
  revisitYn?: 'Y' | 'N';
  /** 독립 공간(룸) 존재 여부 */
  roomYn?: 'Y' | 'N';
  /** 가게 이름 */
  storeName?: string;
  /** 별점 */
  starRating?: number;
}

export interface TopEatsBoardWriteReq {
  attachFiles: File[];
  cateId: string;
  boardType: string;
  contents: string;
  contentsText: string;
  subCateId: string;
  tags: Array<string>;
  title: string;
}

export interface TopEatsBoardUpdateReq extends TopEatsBoardWriteReq {
  boardId: string;
  boardType: string;
  uploadedFiles: AttachFileResDto[];
}

export interface TopEatsBoardLikeReq {
  boardId: string;
}

export interface TopEatsBoardDeleteReq {
  boardId: string;
}

export default {
  getTopEatsBoardList: (params: TopEatsBoardListRequest): Promise<TopEatsBoardListResDto> =>
    request({
      url: endpoints.topEatsBoards.topEatsBoard,
      method: 'get',
      params,
    }),
  getTopEatsBoardDetail: (url: string): Promise<TopEatsBoardDetailResDto> =>
    request({
      url,
      method: 'get',
    }),
  postTopEatsBoardWrite: (params: TopEatsBoardWriteReq): Promise<BaseResDto> => {
    const postParams = {
      title: params.title,
      tags: params.tags,
      subCateId: params.subCateId,
      boardType: params.boardType || 'TOP_EATS',
      contents: params.contents,
      contentsText: params.contentsText,
      cateId: params.cateId,
    };

    if (params.subCateId) {
      postParams['subCateId'] = params.subCateId;
    }

    for (let i = 0; i < params.attachFiles.length; i++) {
      postParams['attachFiles[' + i + '].attachFile'] = params.attachFiles[i];
      postParams['attachFiles[' + i + '].attachFileId'] = '0';
    }

    const formData = paramsToFormData(postParams);

    return request(
      {
        url: endpoints.topEatsBoards.topEatsBoardWrite,
        method: 'post',
        data: formData,
      },
      {
        multiPart: true,
      }
    );
  },
  putTopEatsBoardUpdate: (url: string, params: TopEatsBoardUpdateReq): Promise<BaseResDto> => {
    const postParams = {
      boardId: params.boardId,
      title: params.title,
      tags: params.tags,
      subCateId: params.subCateId,
      contents: params.contents,
      contentsText: params.contentsText,
      cateId: params.cateId,
    };

    const uploadedFilesLength = params.uploadedFiles.length;

    for (let i = 0; i < uploadedFilesLength; i++) {
      postParams['attachFiles[' + i + '].attachFileId'] = params.uploadedFiles[i].fileId;
    }

    for (let i = 0; i < params.attachFiles.length; i++) {
      postParams['attachFiles[' + (uploadedFilesLength + i) + '].attachFile'] = params.attachFiles[i];
      postParams['attachFiles[' + (uploadedFilesLength + i) + '].attachFileId'] = '0';
    }

    const formData = paramsToFormData(postParams);

    return request(
      {
        url: url,
        method: 'put',
        data: formData,
      },
      {
        multiPart: true,
      }
    );
  },
  postTopEatsBoardLike: (params: TopEatsBoardLikeReq): Promise<BaseResDto> =>
    request({
      url: endpoints.topEatsBoards.topEatsBoardLike,
      method: 'post',
      params,
    }),
  deleteTopEatsBoard: (url: string, params?: TopEatsBoardDeleteReq): Promise<BaseResDto> =>
    request({
      url,
      method: 'delete',
      params,
    }),
  shareTopEatsBoard: (params: ShareReqDto): Promise<BaseResDto> =>
    request({
      url: endpoints.topEatsBoards.topEatsBoardShare,
      method: 'post',
      data: params,
    }),
};
